import DefaultPage from "./DefaultPage"
import ProductPage from "../../../../components/page-types/ProductPage"
import RatingsPage from "../../../../components/page-types/RatingsPage"
import NewsPage from "../../../../components/page-types/NewsPage"
import StartPage from "./StartPage"
import CollectionPage from "../../../../components/page-types/CollectionPage"

const PageTypesList = {
  default_page: DefaultPage,
  product_page: ProductPage,
  ratings_page: RatingsPage,
  news_page: NewsPage,
  start_page: StartPage,
  collection_page: CollectionPage,
}

const PageTypes = (type) => {
  if (typeof PageTypesList[type] === "undefined") {
    return console.log("page-type not found")
  }
  return PageTypesList[type]
}

export default PageTypes
