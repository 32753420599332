import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import Breadcrumbs from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Breadcrumbs"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"

import ProductHeroBlock from "../blocks/ProductHeroBlock"

const ProductPage = ({ content, name, breadcrumbs }) => {
  return (
    <Layout>
      <SEO content={content} name={name} />
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <ProductHeroBlock
        block={{
          product_title: content.product_title,
          floating_object: content.floating_object,
          background_image: content.background_image,
          headline: content.headline,
          subheadline: content.subheadline,
          headlineLevel: "h1",
          space_body: content.space_body,
          component: "product-hero-block",
          block_width: "screen",
          cta_label: content.cta_label,
          cta_link: content.cta_link,
        }}
      />
      <BodyBlocks body={content.body} />
    </Layout>
  )
}

export default ProductPage
