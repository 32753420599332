import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import Breadcrumbs from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Breadcrumbs"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"
import HeadlineBlock from "../../components/blocks/HeadlineBlock"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const CollectionPage = ({ content, entries, breadcrumbs, name }) => {
  return (
    <Layout>
      <SEO content={content} title={content.kicker} name={name} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <HeadlineBlock
        block={{
          headline_level: "h1",
          kicker: content.kicker,
          headline: content.headline,
          subheadline: content.subheadline,
        }}
      />
      <BlockWrapper block={{ block_width: "" }}>
        <div className="mt-4 md:masonry-2-col lg:masonry-3-col -mx-grid">
          {entries.nodes.map((item, index) => {
            const content = JSON.parse(item.content)
            return (
              <div className="w-full p-4 break-inside" key={index}>
                <Link
                  className={`block text-white transition-all duration-200 ease-in transform  ${
                    content.card_style ? `bg-${content.card_style}` : `bg-blue`
                  } hover:scale-105 hover:shadow-lg`}
                  link={`/${item.full_slug}`}
                >
                  <div className="p-8">
                    {item.tag_list?.length > 0 && (
                      <span
                        className={`inline-block px-3 py-1 font-bold uppercase bg-white ${
                          content.card_style
                            ? `text-${content.card_style}`
                            : `text-blue`
                        } lg:text-lg font-display`}
                      >
                        {item.tag_list[0]}
                      </span>
                    )}
                    {content.headline?.length > 0 && (
                      <span className="block mt-2 text-2xl font-black hyphens-auto lg:text-3xl font-display">
                        {content.headline}
                      </span>
                    )}
                    {content.subheadline?.length > 0 && (
                      <span className="block mt-4 font-bold">
                        {content.subheadline}
                      </span>
                    )}
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
        <BodyBlocks body={content.body} />
      </BlockWrapper>
    </Layout>
  )
}

export default CollectionPage
