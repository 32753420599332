import React, { useState, useEffect } from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"

const ProductHeroHeader = ({ headline, subheadline, cta_label, cta_link }) => {
  return (
    <div className="product-hero-header md:container mx-auto">
       <div className="flex items-end justify-center min-h-screen">
        <div className="max-w-lg w-full sm:w-1/2 lg:w-2/4 py-6 px-3">
          <p className="product-hero-header-headline headline headline-h4 w-full pb-4">{headline}</p>
          <div className="flex items-center justify-center">
            <h1 className="product-hero-header-subheadline block text-sm leading-6 pr-4">{subheadline}</h1>
            <Link link={cta_link} className="product-hero-header-cta btn btn-primary">{ cta_label }</Link>
          </div>
        </div>
      </div>
    </div>
  )

}


const ProductHeroBlock = ({ block }) => {
  const [spaceAspectRatio, setSpaceAspectRatio] = useState(null)
  const [bgParallaxSpeed, setBgParallaxSpeed] = useState(null)
  let block_space_body = block.space_body


  if (block_space_body[block_space_body.length - 2] &&
    block_space_body[block_space_body.length - 2].component &&
    block_space_body[block_space_body.length - 2].component === "image_block" &&
    block_space_body[block_space_body.length - 1] &&
    block_space_body[block_space_body.length - 1].component &&
    block_space_body[block_space_body.length - 1].component === "info_graphic_block") {
    let imageBlock = block_space_body[block_space_body.length - 2]
    let infoGraphicBlock = block_space_body[block_space_body.length - 1]
    infoGraphicBlock.fallback_image_block = imageBlock
    block_space_body.splice(block_space_body.length - 2)
    block_space_body.push(infoGraphicBlock)
  }

  useEffect(() => {
    if (window && document) {
      var clientWidth = (window.innerWidth > 0) ? window.innerWidth : window.screen.width
      setBgParallaxSpeed(clientWidth <= 768 ? -1.0 : -0.50)

      let hero = document.querySelector('.product-hero-block')
      let spaceRatio = "1by" + (Math.ceil(1 / (hero.offsetWidth / hero.offsetHeight) + 1)).toString()
      setSpaceAspectRatio(spaceRatio)


      window.setTimeout(function () {
        gsap.registerPlugin(ScrollTrigger)

        let hero = document.querySelector('.product-hero-block')
        let hero_stencil_ref = document.querySelector('.blending-stencil-ref')
        let hero_stencil = document.querySelector('.blending-stencil')

        // global timeline
        let tl = gsap.timeline({force3D: true})


        let pinedZoomInTrigger = {
          trigger: hero_stencil_ref,
          pin: true,
          start: "top top",
          end: "bottom top",
          scrub: 1,
          toggleActions: "play none none reset",
          invalidateOnRefresh: true,
        }

        let maskFadeOutInTrigger = {
          trigger: hero_stencil,
          start: "80% center",
          end: "90% bottom",
          scrub: 1,
          invalidateOnRefresh: true,
        }

        // unused browser sniffing
        //window.isSafari = /Version\/([0-9\._]+).*Safari/.test(navigator.userAgent)

        // onload animation
        if (window.scrollY === 0) {
          let splitText = new SplitText(".product-hero-header-headline", {
            type: "words, chars",
          })
          let split = splitText.chars

          tl.from(".blending-stencil img", { duration: 2, opacity: 0, scale: 0.85, ease: "sine" }, 0);
          tl.from(".product-hero-overlay", { duration: 2, opacity: 0, scale: 0.75, ease: "sine" }, 0);
          tl.from(split, {duration: 0.5, autoAlpha: 0, ease: "power1.out", stagger: 0.03 }, 0)

          tl.from(".product-hero-header-subheadline", { duration: 0.5, opacity: 0, ease: "sine" }, 1);
          tl.from(".product-hero-header-cta", { duration: 0.5, opacity: 0, ease: "sine" }, 1);
        }

        // scroll: stencil zoom in animation
        tl.to(".blending-stencil img",
          {
            scale: 60,
            duration: 1,
            force3D: false,
            scrollTrigger: pinedZoomInTrigger
          }
        )
        // scroll: mask fade out
        tl.fromTo(".blending-stencil",
          {
            opacity: 1
          },
          {
            opacity: 0,
            scrollTrigger: maskFadeOutInTrigger
          }
        )

        tl.to(".product-hero-header-container-positive", { duration: 1, opacity: 0, scrollTrigger: Object.assign({}, pinedZoomInTrigger, {end: () => `+=${hero_stencil_ref.offsetHeight/8}`, })});

        // generic parallaxing elements
        gsap.utils.toArray(".product-hero-parallax").forEach(layer => {
          const depth = layer.dataset.depth;
          // make paralax movement for BG depending on hero height, not background layer height
          const movement = -(hero.offsetHeight * depth);

          tl.to(layer, {duration: 1, y: movement, ease: "none", scrollTrigger: {
            trigger: hero,
            start: "top top",
            end: () => `+=${hero.offsetHeight * 2}`,
            scrub: true,
          }});
        });

        ScrollTrigger.refresh(true);
        hero.classList.remove("invisible")
      }, 1200)
    }
  }, [])

  return (
    <>
      {block.background_image && (
        <BlockWrapper block={block} blockPadding="pb-0" className="invisible">
          <div className="relative overflow-hidden">
            {block.background_image && bgParallaxSpeed !== null && (
              <div
                className="product-hero-bg1 product-hero-parallax bg-blending inset-x-0 top-0 absolute w-full h-auto"
                data-depth={bgParallaxSpeed}
              >
                { spaceAspectRatio !== null && (
                  <Image
                    className="block w-full h-auto "
                    image={block.background_image}
                    aspectRatio={spaceAspectRatio}
                    forceLoad={true}
                  />
                )}
              </div>
            )}
            {block.product_title && (
              <div
                className="blending-stencil inset-x-0 top-0 absolute"
              >
                <div
                  className="blending-stencil-ref inset-x-0 top-0 absolute"
                >
                  <Image
                    className="block w-full h-auto"
                    image={block.product_title}
                    forceLoad={true}
                  />
                </div>
              </div>
            )}
            {block.headline && (
              <div className="product-hero-header inset-x-0 absolute">
                <div className="product-hero-header-container product-hero-header-container-positive absolute inset-x-0">
                  <ProductHeroHeader headline={block.headline} subheadline={block.subheadline} cta_label={block.cta_label}  cta_link={block.cta_link} />
                </div>
              </div>
            )}

            {block.floating_object && (
              <div
                className="product-hero-overlay product-hero-parallax absolute z-30 origin-center w-32 md:w-40 2xl:w-48"
                data-depth='-0.70'
              >
                <Image
                  className="block w-auto h-full"
                  image={block.floating_object}
                />
              </div>
            )}
            { block_space_body && block_space_body.length > 0 && (
              <div className="product-hero-body">
                <BodyBlocks body={block_space_body.map((comp) => {
                  comp.invert_color = true
                  return comp
                })} />
              </div>
            )}
          </div>
        </BlockWrapper>
      )}
    </>
  )
}

export default ProductHeroBlock
