import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltRight } from "@fortawesome/pro-solid-svg-icons"
import { Link } from "gatsby"
const Breadcrumbs = ({ breadcrumbs }) => (
  <section>
    <div className="relative px-10 py-4 mx-auto max-w-container lg:px-20">
      <div className="inline-block -mx-2 text-sm">
        {breadcrumbs?.map((breadcrumb, index) => (
          <span key={index}>
            {index > 0 && (
              <span className="inline-block w-4 h-4">
                <FontAwesomeIcon
                  icon={faLongArrowAltRight}
                  size="1x"
                  className=""
                />
              </span>
            )}
            <Link
              className={`px-2 hover:underline ${
                index === 0 || index === breadcrumbs.length - 1
                  ? "text-secondary"
                  : ""
              }`}
              key={index}
              to={breadcrumb.link}
            >
              {breadcrumb.link_text}
            </Link>
          </span>
        ))}
      </div>
    </div>
  </section>
)

export default Breadcrumbs
