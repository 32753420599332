import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import Breadcrumbs from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Breadcrumbs"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"

const DefaultPage = ({ content, name, breadcrumbs }) => {
  return (
    <Layout>
      <SEO content={content} name={name} />
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <BodyBlocks body={content.body} />
    </Layout>
  )
}

export default DefaultPage
