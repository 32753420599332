import React from "react"
import { graphql } from "gatsby"
import PageTypes from "@yumgmbh/gatsby-theme-yum-components/src/components/page-types/_PageTypes"
import Blocks from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/_Blocks"

const RatingsTemplate = ({ data, pageContext }) => {
  const entry = data.entry
  const content = JSON.parse(entry.content)
  const ratings = data.ratings.nodes

  const contentBlocks = (content) => (
    <div>
      {content.body &&
        content.body.map((block) =>
          React.createElement(Blocks(block.component), {
            key: block._uid,
            block: block,
            type: block.component,
          })
        )}
    </div>
  )

  return React.createElement(
    PageTypes(content.component),
    {
      key: entry.uid,
      content: content,
      name: entry.name,
      type: content.component,
      ratings: ratings,
      breadcrumbs: pageContext.breadcrumbs,
    },
    contentBlocks(content)
  )
}

export const query = graphql`
  query RatingsTemplatQuery($id: String, $source: String!) {
    entry: storyblokEntry(id: { eq: $id }) {
      content
      name
      full_slug
    }
    ratings: allGoogleRatingsTableSheet(filter: { table: { eq: $source } }) {
      nodes {
        table
        company
        rating
        tarifname
        filter
      }
    }
  }
`

export default RatingsTemplate
