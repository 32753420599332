import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import Breadcrumbs from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Breadcrumbs"
import HeadlineBlock from "../blocks/HeadlineBlock"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"

const NewsPage = ({ content, name, breadcrumbs, relatedNews }) => {
  return (
    <Layout>
      <SEO content={content} name={name} />
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <HeadlineBlock
        block={{
          headline_level: "h1",
          kicker: content.kicker,
          headline: content.headline,
          subheadline: content.subheadline,
        }}
      />
      <BodyBlocks body={content.body} name={name} relatedNews={relatedNews} />
    </Layout>
  )
}

export default NewsPage
